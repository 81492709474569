import React from 'react'

const QuoteRightGrey = () => (
  <svg
    width="36"
    height="30"
    viewBox="0 0 36 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M7.92 29.664C10.128 29.664 12 28.848 13.536 27.216C15.072 25.488 15.84 23.184 15.84 20.304C15.84 16.176 15.216 12.816 13.968 10.224C12.72 7.536 11.328 5.424 9.792 3.888C8.064 2.16 6 0.864 3.6 0L2.16 2.304C3.792 3.072 5.232 3.984 6.48 5.04C7.536 6 8.544 7.152 9.504 8.496C10.368 9.744 10.8 11.28 10.8 13.104C10.8 13.776 10.704 14.304 10.512 14.688C10.416 14.976 10.272 15.168 10.08 15.264C9.888 15.168 9.6 15.072 9.216 14.976C8.928 14.88 8.64 14.784 8.352 14.688C7.968 14.592 7.584 14.544 7.2 14.544C4.992 14.544 3.264 15.216 2.016 16.56C0.671997 17.808 0 19.536 0 21.744C0 23.952 0.768002 25.824 2.304 27.36C3.84 28.896 5.712 29.664 7.92 29.664ZM28.08 29.664C30.288 29.664 32.16 28.848 33.696 27.216C35.232 25.488 36 23.184 36 20.304C36 16.176 35.376 12.816 34.128 10.224C32.88 7.536 31.488 5.424 29.952 3.888C28.224 2.16 26.16 0.864 23.76 0L22.32 2.304C23.952 3.072 25.392 3.984 26.64 5.04C27.696 6 28.704 7.152 29.664 8.496C30.528 9.744 30.96 11.28 30.96 13.104C30.96 13.776 30.864 14.304 30.672 14.688C30.576 14.976 30.432 15.168 30.24 15.264C30.048 15.168 29.76 15.072 29.376 14.976C29.088 14.88 28.8 14.784 28.512 14.688C28.128 14.592 27.744 14.544 27.36 14.544C25.152 14.544 23.424 15.216 22.176 16.56C20.832 17.808 20.16 19.536 20.16 21.744C20.16 23.952 20.928 25.824 22.464 27.36C24 28.896 25.872 29.664 28.08 29.664Z"
      fill="#020F3D"
    />
  </svg>
)

export default QuoteRightGrey
