import React from 'react'

const QuoteLeft = () => (
  <svg
    width="26"
    height="23"
    viewBox="0 0 26 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.328 15.704C0.328 11.288 2.968 6.08 8.248 0.079999L11.632 2.168C9.136 6.008 7.744 8.936 7.456 10.952L11.704 13.544C11.704 15.224 11.296 16.88 10.48 18.512C9.712 20.144 8.92 21.416 8.104 22.328C5.416 22.328 3.28 21.608 1.696 20.168C0.784 19.352 0.328 17.864 0.328 15.704ZM14.368 15.704C14.368 11.288 17.008 6.08 22.288 0.079999L25.672 2.168C23.176 6.008 21.784 8.936 21.496 10.952L25.744 13.544C25.744 15.224 25.336 16.88 24.52 18.512C23.752 20.144 22.96 21.416 22.144 22.328C19.456 22.328 17.32 21.608 15.736 20.168C14.824 19.352 14.368 17.864 14.368 15.704Z"
      fill="#FC4E9F"
    />
  </svg>
)

export default QuoteLeft
