import styled from 'styled-components'
import { media } from '../../../../lib/constants'

export const Newsletter = styled.div`
  background: #f7f6f6;
  margin: 4.25rem 0 8rem;
  width: 100%;
  display: flex;

  > div {
    align-items: center;

    > div {
      flex: 1;
    }
  }

  form {
    margin-bottom: 0;
  }

  img {
    margin-bottom: 0;
    width: 100%;
  }

  ${media.desktop`
    > div {
      flex-direction: column;
      width: 100%;
    }
  `}

  ${media.tablet`
    flex-direction: column;
    margin: 0 0 3.75rem;
  `}
`

export const Box = styled.div`
  padding: 3rem 100px;

  ${media.desktop`
    padding: 3rem;
  `}

  ${media.tablet`
    padding: 50px 30px 40px;
  `}

  h3 {
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 44px;
  }

  input {
    height: 55px;
    border-radius: 4px;
    max-width: 390px;
    width: 100%;
  }

  button {
    min-width: 185px;
  }
`

export const FormGroup = styled.div`
  margin-bottom: 1.25rem;
`
