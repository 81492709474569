import React from 'react'

const QuoteLeftGrey = () => (
  <svg
    width="37"
    height="30"
    viewBox="0 0 37 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M28.84 29.664C26.632 29.664 24.76 28.848 23.224 27.216C21.688 25.488 20.92 23.184 20.92 20.304C20.92 16.176 21.544 12.816 22.792 10.224C24.04 7.536 25.432 5.424 26.968 3.888C28.696 2.16 30.76 0.864 33.16 0L34.6 2.304C32.968 3.072 31.528 3.984 30.28 5.04C29.224 6 28.216 7.152 27.256 8.496C26.392 9.744 25.96 11.28 25.96 13.104C25.96 13.776 26.056 14.304 26.248 14.688C26.344 14.976 26.488 15.168 26.68 15.264C26.872 15.168 27.16 15.072 27.544 14.976C27.832 14.88 28.12 14.784 28.408 14.688C28.792 14.592 29.176 14.544 29.56 14.544C31.768 14.544 33.496 15.216 34.744 16.56C36.088 17.808 36.76 19.536 36.76 21.744C36.76 23.952 35.992 25.824 34.456 27.36C32.92 28.896 31.048 29.664 28.84 29.664ZM8.68001 29.664C6.47201 29.664 4.60001 28.848 3.06401 27.216C1.52801 25.488 0.76001 23.184 0.76001 20.304C0.76001 16.176 1.38401 12.816 2.63201 10.224C3.88001 7.536 5.27201 5.424 6.80801 3.888C8.53601 2.16 10.6 0.864 13 0L14.44 2.304C12.808 3.072 11.368 3.984 10.12 5.04C9.06401 6 8.05601 7.152 7.09601 8.496C6.23201 9.744 5.80001 11.28 5.80001 13.104C5.80001 13.776 5.89601 14.304 6.08801 14.688C6.18401 14.976 6.32801 15.168 6.52001 15.264C6.71201 15.168 7.00001 15.072 7.38401 14.976C7.67201 14.88 7.96001 14.784 8.24801 14.688C8.63201 14.592 9.01601 14.544 9.40001 14.544C11.608 14.544 13.336 15.216 14.584 16.56C15.928 17.808 16.6 19.536 16.6 21.744C16.6 23.952 15.832 25.824 14.296 27.36C12.76 28.896 10.888 29.664 8.68001 29.664Z"
      fill="#020F3D"
    />
  </svg>
)

export default QuoteLeftGrey
