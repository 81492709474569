import React from 'react'
import QuoteLeft from '../Icon/QuoteLeft'
import QuoteLeftGrey from '../Icon/QuoteLeftGrey'
import QuoteRight from '../Icon/QuoteRight'
import QuoteRightGrey from '../Icon/QuoteRightGrey'
import * as S from './BlockQuote.css'

const BlockQuote = ({
  children,
  center,
  fontSize,
  hasLine,
  greyIcon,
  hasShadow,
}) => {
  return (
    <S.BlockQuote
      center={center}
      fontSize={fontSize}
      hasLine={hasLine}
      hasShadow={hasShadow}
    >
      {greyIcon ? <QuoteLeftGrey /> : <QuoteLeft />}
      {children}
      {greyIcon ? <QuoteRightGrey /> : <QuoteRight />}
    </S.BlockQuote>
  )
}

BlockQuote.defaultProps = {
  hasLine: false,
  center: false,
  fontSize: '30px',
  greyIcon: false,
}

export default BlockQuote
