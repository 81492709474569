import styled from 'styled-components'
import { colors, fonts, media, textSm } from '../../../lib/constants'

export const ViewMore = styled.div`
  text-align: center;
  padding-top: 4.8rem;

  ${media.tablet`
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  `}
`

export const Heading = styled.h3`
  color: ${colors.blue};
  font-family: ${fonts.headings};
  font-weight: normal;
  margin-bottom: 2.5rem;
  text-align: center;
`

export const HeroImg = styled.img`
  margin-bottom: 100px;
  width: 100%;

  ${media.tablet`
    margin-bottom: 2.75rem;
  `}
`

export const BlockQuoteWrap = styled.div`
  margin-bottom: 3.75rem;
`

export const Content = styled.div`
  margin-bottom: 3.75rem;
  padding-right: 100px;
  position: relative;

  p:last-child {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    font-family: ${fonts.headings};
    font-size: 24px;
    color: ${colors.blue};
    position: relative;
    padding-top: 30px;
    margin-bottom: 20px;

    &::after {
      position: absolute;
      content: ' ';
      width: 50px;
      height: 5px;
      background-color: ${colors.pink};
      left: 0;
      top: 15px;
    }
  }

  ${media.tablet`
    padding-right: 0;
  `}
`

export const Tags = styled.div`
  ${textSm}
  border-top: 1px solid #999DAE;
  padding-top: 30px;
`

export const SocialShareWrap = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  button {
    background: none;
    display: block;
    margin-bottom: 15px;
    line-height: 0;
    width: 25px;
    height: 25px;

    svg {
      width: 25px;
      height: 25px;
    }
  }

  ${media.tablet`
    position: relative;

    button {
      display: inline-block;
      margin-right: 1rem;
      margin-bottom: 0;
    }
  `}
`
