import React from 'react'
import { graphql } from 'gatsby'
import { Layout, Container } from '../layouts'
import { Helmet } from 'react-helmet'
import htmr from 'htmr'
import Hero from '../components/Global/Hero'
import Heading from '../components/Global/Heading'
import Article from '../components/Global/Article'
import BlockQuote from '../components/Global/BlockQuote'
import { Row, Col } from '../layouts/grid.css'
import Newsletter from '../components/Global/Newsletter'
import { colors } from '../../lib/constants'
import FacebookShareButton from 'react-share/lib/FacebookShareButton'
import TwitterShareButton from 'react-share/lib/TwitterShareButton'
import Facebook from '../components/Global/Icon/Facebook'
import Twitter from '../components/Global/Icon/Twitter'

import * as S from '../components/Blog/Blog.css'

const getRandomPost = (items) => {
  return items[Math.round(Math.random() * (items.length - 1))]
}

const postExists = (post, posts) => {
  return posts.find((it) => it.id === post.id)
}

const getRandomRelatedPosts = (number, posts) => {
  if (!posts) return []
  if (posts.length <= number) {
    return posts
  }
  let randomPosts = []

  for (let i = 0; i < number; i++) {
    let post
    do {
      post = getRandomPost(posts)
    } while (postExists(post, randomPosts))
    randomPosts.push(post)
  }

  return randomPosts
}

const BlogPost = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark
  const {
    title,
    date,
    author,
    tags,
    hero_image,
    category,
    blockquote,
    description,
  } = frontmatter
  const relatedPosts = getRandomRelatedPosts(
    3,
    data.markdownRemark.relatedPosts
  )

  return (
    <Layout>
      <Helmet>
        <title>{title} - Blog - TechEquipt</title>
        <meta name="description" content={description} />
      </Helmet>

      <Hero
        size="large"
        title={title}
        postTitle={category}
        extra={
          <>
            <span>by {author}</span> | <span>{date}</span>
          </>
        }
      />
      <Container>
        {hero_image && <S.HeroImg src={hero_image.publicURL} alt={title} />}
        {blockquote && (
          <S.BlockQuoteWrap>
            <BlockQuote>{blockquote}</BlockQuote>
          </S.BlockQuoteWrap>
        )}
        <S.Content>
          {htmr(html)}

          <S.SocialShareWrap>
            <FacebookShareButton url={location.href} quote={title}>
              <Facebook color={colors.blue} />
            </FacebookShareButton>
            <TwitterShareButton title={title} url={location.href}>
              <Twitter color={colors.blue} />
            </TwitterShareButton>
          </S.SocialShareWrap>
        </S.Content>
        {tags.length > 0 && (
          <S.Tags>{tags.length > 1 ? tags.join(', ') : tags[0]}</S.Tags>
        )}
      </Container>

      <Container>
        <Newsletter style={{ marginBottom: 0 }} />
      </Container>

      {relatedPosts && relatedPosts.length > 0 && (
        <Container
          color={colors.faded__blue}
          padding="8rem 0 4rem"
          mobilePadding="5rem 0 2rem"
        >
          <Heading
            hasLine
            level={3}
            lineSize={5}
            style={{ fontSize: '2rem', marginBottom: 40 }}
          >
            Related blog
          </Heading>
          <Row gutter={10}>
            {relatedPosts.map((article, key) => (
              <Col key={key} md={4}>
                <Article slug={article.fields.slug} {...article.frontmatter} />
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        category
        templateKey
        hero_image {
          publicURL
        }
        date(formatString: "MMMM Do, YYYY")
        tags
        description
        blockquote
        author
      }
      relatedPosts {
        id
        fields {
          slug
        }
        frontmatter {
          title
          category
          templateKey
          hero_image {
            publicURL
          }
          date(formatString: "MMMM Do, YYYY")
          tags
          description
          author
        }
      }
    }
  }
`

export default BlogPost
