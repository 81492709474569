import React from 'react'

const QuoteRight = () => (
  <svg
    width="26"
    height="23"
    viewBox="0 0 26 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.672 15.704C25.672 11.288 23.032 6.08 17.752 0.079999L14.368 2.168C16.864 6.008 18.256 8.936 18.544 10.952L14.296 13.544C14.296 15.224 14.704 16.88 15.52 18.512C16.288 20.144 17.08 21.416 17.896 22.328C20.584 22.328 22.72 21.608 24.304 20.168C25.216 19.352 25.672 17.864 25.672 15.704ZM11.632 15.704C11.632 11.288 8.992 6.08 3.712 0.079999L0.327998 2.168C2.824 6.008 4.216 8.936 4.504 10.952L0.255999 13.544C0.255999 15.224 0.663999 16.88 1.48 18.512C2.248 20.144 3.04 21.416 3.856 22.328C6.544 22.328 8.68 21.608 10.264 20.168C11.176 19.352 11.632 17.864 11.632 15.704Z"
      fill="#FC4E9F"
    />
  </svg>
)

export default QuoteRight
