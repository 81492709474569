import styled, { css } from 'styled-components'
import {
  fonts,
  media,
  colors,
  shadows,
  transitions,
} from '../../../../lib/constants'

export const BlockQuote = styled.blockquote`
  margin: 0 auto;
  max-width: 800px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '30px')};
  line-height: 1.5;
  position: relative;
  padding: 0 60px;
  font-style: italic;
  font-weight: 500;
  color: ${colors.grey};

  svg {
    position: absolute;
    left: 0;
    top: 0;

    &:last-child {
      right: 0;
      bottom: 0;
      top: auto;
      left: auto;
    }
  }

  ${(props) =>
    props.center &&
    css`
      text-align: center;
      max-width: 800px;
      margin: 0 auto;
      padding: 0;
      font-family: ${fonts.headings};
    `}

  ${(props) =>
    props.hasLine &&
    css`
      margin-top: 30px;

      &:before {
        content: ' ';
        display: block;
        height: 5px;
        width: 80px;
        background-color: ${colors.pink};
        top: -30px;
        position: absolute;
        left: 60px;
      }
    `}

  ${media.tablet`
    padding: 0 2rem;
    font-size: 1rem;

    ${(props) =>
      props.hasLine &&
      css`
        padding-left: 0;

        &:before {
          left: 0;
          top: -20px;
        }

        svg {
          width: 24px;
          height: 20px;

          &:first-child {
            top: -55px;
          }
        }
      `}
  `}

  ${(props) =>
    props.hasShadow &&
    css`
      box-shadow: ${shadows.projectCard};
      background-color: ${colors.white};
      border-radius: 30px;
      padding: 3rem;
      transform: rotate(-2deg);
      transition: transform ${transitions.slow};

      svg {
        width: 60px;
        height: 60px;
        transition: transform ${transitions.slow};
        transform: rotate(0deg);

        top: -1rem;
        left: -1rem;

        &:last-child {
          right: -1rem;
          bottom: -1rem;
        }
      }

      &:hover {
        transform: rotate(0deg);

        svg {
          transform: rotate(360deg);
        }
      }

      ${media.tablet`
        padding: 2rem;
        transform: rotate(0deg);
        svg {
          width: 50px;
          height: 50px;
        }
      `}
    `}
`
