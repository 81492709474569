import React from 'react'
import Heading from '../Heading'
import Button from '../Button'
import { Row, Col } from '../../../layouts/grid.css'
import * as S from './Newsletter.css'

import NewsletterImg from './images/newsletter.jpg'

const Newsletter = props => {
  return (
    <S.Newsletter {...props}>
      <Row>
        <Col lg={5} image={NewsletterImg}>
          <img src={NewsletterImg} alt="" />
        </Col>
        <Col lg={7}>
          <S.Box>
            <Heading level={3}>Get update on our news!</Heading>
            <p>Let us know your email and we will get you update</p>
            <form>
              <S.FormGroup>
                <input type="email" required placeholder="Email address" />
              </S.FormGroup>
              <Button type="submit">Submit</Button>
            </form>
          </S.Box>
        </Col>
      </Row>
    </S.Newsletter>
  )
}

export default Newsletter
